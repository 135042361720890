@import "~@angular/material/theming";

@include mat-core();
$md-pvetprimary: (
  50: #eae0e3,
  100: #cbb3b9,
  200: #a9808a,
  300: #864d5b,
  400: #6c2637,
  500: #520014,
  600: #4b0012,
  700: #41000e,
  800: #38000b,
  900: #280006,
  A100: #ff636a,
  A200: #ff3039,
  A400: #fc000b,
  A700: #e2000a,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$md-pvetaccent: (
  50: #f0feff,
  100: #d9fdff,
  200: #c0fbff,
  300: #a6f9ff,
  400: #93f8ff,
  500: #80f7ff,
  600: #70caf2, //#78f6ff,
  700: #6df5ff,
  800: #63f3ff,
  900: #50f1ff,
  A100: #ffffff,
  A200: #ffffff,
  A400: #f6feff,
  A700: #dcfcff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// $primary-theme-color: mat-palette($mat-gray, 900);
// $accent-theme-color: mat-palette($mat-cyan, 600);

$primary-theme-color: mat-palette($md-pvetprimary, 500);
$accent-theme-color: mat-palette($md-pvetaccent, 600);

$secondary-theme-color: #fd2b5c;
$primary-color: #520014;

$mat-cantera-clubs-theme: mat-light-theme(
  $primary-theme-color,
  $accent-theme-color
);

$enable-shadows: true;

@include angular-material-theme($mat-cantera-clubs-theme);

$physical-category-color: #00ff87;
$tactical-category-color: #ff015b;
$behavioral-category-color: #e74cf5;
$technical-category-color: #05f1ff;
